









































































































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import { ContractApi } from "@/networks/ContractApi";
import Transactions from "@/views/pages/Profile/File/Detail/Transactions.vue";
import Enums from "@/config/enum";
import Event from "@/views/components/pages/Contract/EventCard.vue";

@Component({
  components: { Transactions, Event }
})
export default class component_name extends Vue {
  desktop = window.innerWidth > 600 ? true : false;
  contract: any = {};
  migrationMethods = Enums.MigrationMethods;
  offices = Enums.all_money_i_have;
  private baseUrl = process.env.VUE_APP_URL || "";

  get migrationContract() {
    return this.migrationMethods.find(
      (x) => x.value == this.contract.migration_method
    );
  }

  async mounted() {
    const id = this.$route.params.id;
    const data = await new ContractApi().getItem(id);
    this.contract = data.data.data;
  }
}
