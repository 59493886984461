



















































































































































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import moment from "moment-jalaali";
import Enum from "@/config/enum";
@Component
export default class Events extends Vue {
  @Prop()
  event!: any;

  private eventStatus = Enum.eventStatusAdmin;
  FormatDate(date: string, time: string) {
    return (
      ` ${time ? time.substr(0, 5) : ""} ` +
      moment(date).format("jYYYY/jMM/jDD")
    );
  }
  PaymentType(payment: any) {
    return "موفق";
  }
  CounselingStatus(status: any) {
    return "مشاوره شد";
  }
  CounselingStatusColor(status: any) {
    return "green";
  }
}
